export const styles = {
  global: {
    "body": {
      color: '#212427'
    },
    ":root": {
      // "font-family": "Inter, system-ui, Avenir, Helvetica, Arial, sans-serif",
      "lineHeight": "1.5",
      "fontHeight": "400",
      "--accent": "black",
      "--canvas": "rgba(245, 245, 245, 0.99)", // Approximation of `color-mix`
      "--border": "rgba(200, 200, 200, 0.9)", // Approximation of `color-mix`
      "--text": "rgba(50, 50, 50, 0.75)", // Approximation of `color-mix`
      "--border-radius": "24px",
      "--border-width": "3px",
      "--trail-size": "25px",
    },
    "html, body, #root": {
      height: "100%",
      margin: "0"
    },
    ".dndflow": {
      "flexDirection": "column",
      display: "flex",
      "flexGrow": "1",
      height: "100%"
    },
    ".dndflow aside": {
      "borderRight": "1px solid #eee",
      padding: "15px 10px",
      "fontSize": "12px",
      background: "#fcfcfc"
    },
    "a": {
      'textDecoration': "none !important"
    },
    ".is-active": {
      background: "#f9f9f9"
    },
    ".dndflow aside .description": {
      "marginBottom": "10px"
    },
    ".dndflow .reactflow-wrapper": {
      "flexGrow": "1",
      height: "100%"
    },
    ".dndflow .selectall": {
      "marginTop": "10px"
    },
    "@media screen and (min-width: 768px)": {
      ".dndflow": {
        "flexDirection": "row"
      },
      ".dndflow aside": {
        width: "20%",
        "maxWidth": "250px"
      }
    },
    ".default-box": {
      border: "1px solid black",
      "boxShadow": "0 0 5px rgba(0, 0, 0, 0.2)"
    },
    ".run-connected": {
      color: "white",
      "fontWeight": "900"
    },
    ".run-connected::before": {
      content: '""',
      position: "absolute",
      top: "-2px",
      left: "-2px",
      right: "-2px",
      bottom: "-2px",
      "borderRadius": "inherit",
      background: "linear-gradient(45deg, #ff7f7f, #d1ffbd, #d1ffbd)",
      "backgroundSize": "400%",
      animation: "glow 3s infinite linear",
      "zIndex": "-1"
    },
    ".edge-hovered": {
      color: "#ff7f7f",
      "backgroundColor": "#ff7f7f",
      "strokeWidth": "4px"
    },
    ".rjsf": {
      width: "100%"
    },
    ".react-flow__node-annotation": {
      "font-size": '16px',
      width: "200px",
      color: "#212427",
      "font-family": "monospace",
      position: "absolute",
    },
    ".react-flow__node-annotation .arrow": {
      position: "absolute",
      "font-size": "24px"
    },
    ".animate-on-hover": {
      transition: "all 0.2s",
      "will-change": "transform",
      "transform": "translateY(0)"
    },
    ".animate-on-hover:hover": {
      "transform": "translateY(-1px)"
    },
    ".lp-card": {
      // "boxShadow": "4px 8px 8px #DCDEF6",
      // full black 
      // "boxShadow": "10px 10px 0 8px #000",
      "shadow": { base: 0, md: 'md' },
      "borderWidth": { base: 0, md: '1px' },
      "borderRadius": { base: 0, md: "10px", },
      "borderColor": "gray.300"
    },
    ".lp-bg-img": {
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      rounded: { base: 0, md: '10' },
      zIndex: "-1",
      opacity: 0.8
    },
    ".animated-border": {
      position: "relative",
      background: "white",
    },
    ".animated-border::before": {
      content: '""',
      position: "absolute",
      top: "-4px",
      left: "-4px",
      right: "-4px",
      bottom: "-4px",
      borderRadius: "inherit",
      background: "linear-gradient(90deg, #F7B1D2, #727ADB, #F7B1D2)",
      backgroundSize: "200% 200%",
      animation: "moveGradient 3s linear infinite, glowPulse 3s linear infinite",
      zIndex: "-1"
    },
    "@keyframes moveGradient": {
      "0%": {
        backgroundPosition: "0% 50%",
      },
      "50%": {
        backgroundPosition: "100% 50%",
      },
      "100%": {
        backgroundPosition: "0% 50%",
      }

    },
    "@keyframes glowPulse": {
      "0%, 100%": {
        boxShadow: "0 0 5px #727ADB80",
      },
      "50%": {
        boxShadow: "0 0 25px #727ADB",
      }
    }
  }
};
